<template>
  <div id="faqView">
    <SimpleContentBlock
      v-if="simpleContentData"
      :simple-content-data="simpleContentData"
    />
    <FaqAccordion :faq-accordion-data="faqAccordionData" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import SimpleContentBlock from '@/components/UI/SimpleContentBlock'
import FaqAccordion from '@/components/Accordion/FaqAccordion'

export default {
  name: 'FAQ',
  components: { FaqAccordion, SimpleContentBlock },
  data() {
    return {
      simpleContentData: null,
      faqAccordionData: [],
      loaded: false,
    }
  },
  mounted() {
    this.loadFaqView()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadFaqView() {
      this.setFaqData(helpers.readStoredResponse('view.faq'))

      api.views.loadFaq().then((response) => {
        this.setFaqData(response)
      })
    },
    setFaqData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.simpleContentData = entry.simpleContentBlock[0] || null
      this.faqAccordionData = entry.faqAccordion || []

      if (!this.loaded) {
        setTimeout(() => {
          this.setPageLoadingFalse()
        }, 500)
        this.loaded = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#faqView {
  @apply bg-black;
  margin-bottom: 200px;
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 700px;
}
</style>
