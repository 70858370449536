<template>
  <div class="faq-accordion-list">
    <Accordion
      :class="[{ invisible: !visibleIds[accordionItem.id] }, 'accordion-item']"
      v-for="accordionItem in faqAccordionData"
      :key="accordionItem.id"
    >
      <template v-slot:header>
        <div class="container accordion-header">
          <h3>{{ accordionItem.question }}</h3>
        </div>
      </template>
      <template v-slot:content>
        <div class="container accordion-content">
          <div class="answer" v-html="accordionItem.answer"></div>
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/UI/Accordion'
export default {
  name: 'FaqAccordion',
  components: { Accordion },
  props: {
    faqAccordionData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visibilityDelay: 300,
      visibleIds: {},
    }
  },
  watch: {
    faqAccordionData(newVal) {
      if (newVal.length) this.setVisibleIds()
    },
  },
  mounted() {
    this.setVisibleIds()
  },
  methods: {
    setVisibleIds() {
      this.faqAccordionData.forEach((accordionItem) => {
        const delay = this.visibilityDelay
        this.visibilityDelay = this.visibilityDelay + 300

        setTimeout(() => {
          this.visibleIds[accordionItem.id] = true
        }, delay)
      })
    },
  },
}
</script>

<style lang="scss">
.faq-accordion-list {
  @apply overflow-x-hidden;

  .accordion-item.closed {
    .accordion-header {
      h3 {
        background-color: #080707;
      }
    }
  }

  .accordion-item {
    @apply opacity-100;
    transform: translateX(0);
    transition: all 0.75s ease-in;

    &.invisible {
      @apply opacity-0;

      &:nth-child(even) {
        transform: translateX(-200px);
      }

      &:nth-child(odd) {
        transform: translateX(200px);
      }
    }

    .accordion-header {
      margin-top: 0.5em !important;

      h3 {
        @apply py-5;
        font-family: OswaldLight, serif;
        font-size: 20px;
        color: #be5f00;
        background-color: #231f20;
      }
    }

    .accordion-content {
      .answer {
        @apply text-left py-10 px-6;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 1.1em;
        color: #cfcfcf;
        border: 1px solid #080707;

        a {
          color: #f17800;
          &:hover {
            color: #d76b00;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .accordion-item {
      .accordion-header {
        h3 {
          font-size: 26px;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .accordion-item {
      .accordion-header {
        h3 {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
