<template>
  <div :class="[{ closed: closed }, 'accordion-container']">
    <div class="accordion-header" @click="toggleContainer">
      <slot name="header"></slot>
    </div>
    <div class="accordion-content" ref="accordionContent">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  data() {
    return {
      closed: false, // Will be set to true once mounted.
      height: 0,
    }
  },
  mounted() {
    this.initAccordionItem()
  },
  methods: {
    initAccordionItem() {
      this.height = this.$refs.accordionContent.clientHeight
      this.$refs.accordionContent.style.maxHeight = 0
      this.closed = true
    },
    toggleContainer() {
      if (this.$refs.accordionContent.style.maxHeight !== '0px') {
        this.$refs.accordionContent.style.maxHeight = 0
        this.closed = true
      } else {
        this.$refs.accordionContent.style.maxHeight = this.height + 'px'
        this.closed = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion-container {
  .accordion-header {
    @apply cursor-pointer;
  }

  .accordion-content {
    @apply overflow-hidden opacity-100;
    transition: all 0.3s linear;
  }

  &.closed {
    .accordion-content {
      @apply overflow-hidden opacity-0;
    }
  }
}
</style>
